
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/shop/[[...category]]",
      function () {
        return require("private-next-pages/shop/[[...category]]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/shop/[[...category]]"])
      });
    }
  