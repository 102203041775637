import {NextPageContext} from 'next'
import {fetchSSRData} from 'src/page-utils'
import isEmpty from 'lodash/isEmpty'
import Shop from 'src/Screens/Shop'
import {FilterProvider} from 'src/Screens/Shop/FilterContext'
import {getFiltersFromQueryParams} from 'src/Screens/Shop/shop.utils'

function ShopPage(props: any) {
  const {pageData, initialFilters = {}} = props

  return (
    <FilterProvider initialFilters={initialFilters}>
      <Shop pageData={pageData} />
    </FilterProvider>
  )
}

export async function getServerSideProps(context: NextPageContext) {
  const {query} = context

  const response = await fetchSSRData(context, [
    {key: 'data', url: `page/mwsc/shop?format=w`, isPrimary: true},
  ])

  let pageData = response?.props?.pageData || {}

  if (isEmpty(pageData)) {
    return {
      redirect: {
        destination: '/allproducts',
        permanent: false,
      },
    }
  }

  const initialFilters = getFiltersFromQueryParams(query)

  return {
    props: {
      pageData: pageData,
      initialFilters,
    },
  }
}

export default ShopPage
