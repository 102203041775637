interface IBreadCrumbs {
  options: any[]
}

export function structuredBreadCrumbsSEO(breadCrumbs: IBreadCrumbs) {
  const {options = []} = breadCrumbs || {}
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: options.map((option: any, index: number) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: option.title,
        item: option.canonical || option.link,
      }
    }),
  }
}
