import React, {useMemo} from 'react'
import Head from 'next/head'
import {structuredBreadCrumbsSEO} from 'src/utils/structuredBreadCrumbsSEO'
import isEmpty from 'lodash/isEmpty'
import {getOptimizedImageKitLink} from '../shared/src/helpers/getOptimizedImageKitLink'
import {useDeviceTypeContext} from '@web-components'
function ProductListSEO({meta = {}, breadCrumbs, faqs}: any) {
  const {
    title = '',
    description = '',
    keywords = '',
    noIndex = false,
    canonical = '',
    preloadImage = '',
  } = meta || {}
  const structuredBreadCrumbs = structuredBreadCrumbsSEO(breadCrumbs)

  const {isMobile} = useDeviceTypeContext()

  const structuredFaqs = useMemo(() => {
    const {questionAndAnswers = [], answers = []} = faqs || {}
    const myQnaArray = isEmpty(questionAndAnswers)
      ? answers
      : questionAndAnswers
    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: myQnaArray?.map((questionAndAnswer: any) => {
        const {
          question = '',
          answer = '',
          title = '',
          content = '',
        } = questionAndAnswer || {}
        return {
          '@type': 'Question',
          name: question || title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer || content,
          },
        }
      }),
    }
  }, [faqs])

  const modifiedPreloadImage = useMemo(() => {
    return (
      !!preloadImage &&
      getOptimizedImageKitLink(preloadImage, 'FULL', 'FULL', isMobile)
    )
  }, [isMobile, preloadImage])

  return (
    <div>
      <Head>
        {noIndex && <meta name="robots" content="noindex" />}
        {!!title && <title>{title}</title>}
        {description && <meta name="description" content={description}></meta>}
        {canonical && <link rel="canonical" href={canonical} />}
        {keywords && <meta name="keywords" content={keywords}></meta>}
        {!!modifiedPreloadImage && (
          <link
            rel="preload"
            href={modifiedPreloadImage}
            as="image"
            fetchPriority="high"
          />
        )}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredBreadCrumbs),
          }}
        />
        {!!faqs && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{__html: JSON.stringify(structuredFaqs)}}
          />
        )}
      </Head>
    </div>
  )
}

export default ProductListSEO
